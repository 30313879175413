var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"more"},[_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("报告单号：")]),_c('span',[_vm._v(_vm._s(_vm.bgdh))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("姓名：")]),_c('span',[_vm._v(_vm._s(_vm.data.xm))])]),_c('div',[_c('span',[_vm._v("性别：")]),_c('span',[_vm._v(_vm._s(_vm.data.xb))])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("年龄：")]),_c('span',[_vm._v(_vm._s(_vm.data.age))])]),_c('div',[_c('span',[_vm._v("科室：")]),_c('span',[_vm._v(_vm._s(_vm.data.kb))])])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("就诊卡号：")]),_c('span',[_vm._v(_vm._s(_vm.data.jzkh))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("样本种类：")]),_c('span',[_vm._v(_vm._s(_vm.data.bbzl))])]),_vm._m(0)]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("送检日期：")]),_c('span',[_vm._v(_vm._s(_vm.data.sjrq))])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("采样日期：")]),_c('span',[_vm._v(_vm._s(_vm.data.cyrq))])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("临床诊断：")]),_c('span',[_vm._v(_vm._s(_vm.data.lczd))])]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"row"},[_c('div',{staticClass:"left"},[_c('span',[_vm._v("送检医师：")]),_c('span',[_vm._v(_vm._s(_vm.data.sjys))])]),_c('div',[_c('span',[_vm._v("检验医师：")]),_c('span',[_vm._v(_vm._s(_vm.data.jyys))])])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("核对者：")]),_c('span',[_vm._v(_vm._s(_vm.data.hdz))])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("检验日期：")]),_c('span',[_vm._v(_vm._s(_vm.data.jyrq))])]),_c('div',{staticClass:"row"},[_c('span',[_vm._v("报告日期：")]),_c('span',[_vm._v(_vm._s(_vm.data.jyrq))])]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("床号：")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('span',[_vm._v("标本说明：")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('span',[_vm._v("病人特征：")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('span',[_vm._v("病区：")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('span',[_vm._v("发布日期：")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb0"},[_c('span',[_vm._v("备注：")]),_c('span')])
}]

export { render, staticRenderFns }