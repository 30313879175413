<template>
  <div class="more">
    <div class="card-box">
      <div class="title">
        <span>报告单号：</span>
        <span>{{ bgdh }}</span>
      </div>
      <div class="row">
        <div class="left">
          <span>姓名：</span>
          <span>{{ data.xm }}</span>
        </div>
        <div>
          <span>性别：</span>
          <span>{{ data.xb }}</span>
        </div>
      </div>
      <div class="row">
        <div class="left">
          <span>年龄：</span>
          <span>{{ data.age }}</span>
        </div>
        <div>
          <span>科室：</span>
          <span>{{ data.kb }}</span>
        </div>
      </div>
      <div class="row">
        <span>就诊卡号：</span>
        <span>{{ data.jzkh }}</span>
      </div>
      <div class="row">
        <div class="left">
          <span>样本种类：</span>
          <span>{{ data.bbzl }}</span>
        </div>
        <div>
          <span>床号：</span>
          <span />
        </div>
      </div>
      <div class="row">
        <span>送检日期：</span>
        <span>{{ data.sjrq }}</span>
      </div>
      <div class="row">
        <span>采样日期：</span>
        <span>{{ data.cyrq }}</span>
      </div>
      <div class="row">
        <span>临床诊断：</span>
        <span>{{ data.lczd }}</span>
      </div>
      <div class="row">
        <span>标本说明：</span>
        <span />
      </div>
      <div class="row">
        <span>病人特征：</span>
        <span />
      </div>
      <div class="row">
        <span>病区：</span>
        <span />
      </div>
      <div class="row">
        <div class="left">
          <span>送检医师：</span>
          <span>{{ data.sjys }}</span>
        </div>
        <div>
          <span>检验医师：</span>
          <span>{{ data.jyys }}</span>
        </div>
      </div>
      <div class="row">
        <span>核对者：</span>
        <span>{{ data.hdz }}</span>
      </div>
      <div class="row">
        <span>检验日期：</span>
        <span>{{ data.jyrq }}</span>
      </div>
      <div class="row">
        <span>报告日期：</span>
        <span>{{ data.jyrq }}</span>
      </div>
      <div class="row">
        <span>发布日期：</span>
        <span />
      </div>
      <div class="row mb0">
        <span>备注：</span>
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import {patientModel} from "../../../api/patient"

export default {
  name: 'CheckoutMore',
  
  data() {
    return {
      data:{},
      bgdh:this.$route.query.bgdh
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData(){
      let params={
        // patid: this.$route.query.patid ||'',
        bgdh: this.$route.query.bgdh ||'',
        patxm: this.$route.query.patxm ||'',
        zjh: this.$route.query.zjh ||''
      }
      patientModel.jyReportDetail(params).then(res=>{
        this.data = res.data
      })
    }
  }
}
</script>

<style scoped lang='less'>
@import "../../../assets/styles/variable.less";
.more {
    padding: 32px;

    .card-box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 12px;
        padding: 32px;
        box-sizing: border-box;

        .title {
            font-size: 32px;
            font-weight: bold;
            color: #3D3D3D;
            line-height: 45px;
            margin-bottom: 32px;
        }

        .row {
            display: flex;
            line-height: 40px;
            font-size: 28px;
            margin-bottom: 24px;
            color: #666666;

            .left {
                text-align: left;
                width: 325px;
            }    
        }

        .mb0 {
            margin-bottom: 0px;
        }
    }
}

</style>
